import {gsap} from "gsap";

const artistName = document.querySelector(".collage .artist__name");
const artistCountry = document.querySelector(".collage .artist__country");
const artistRichText = document.querySelector(".collage__text");
const artistDate = document.querySelector(".collage__date");
const artistTime = document.querySelector(".collage__time");
const artistScene = document.querySelector(".collage__scene");
const artistSpotify = document.querySelector(".collage__spotify");

const image1 = document.querySelector(".collage-image--1");
const image2 = document.querySelector(".collage-image--2");
const image3 = document.querySelector(".collage-image--3");
const image4 = document.querySelector(".collage-image--4");
const image5 = document.querySelector(".collage-image--5");
const imagesArray = [image5, image1, image2, image3, image4];

const collage = document.querySelector(".collage");
const collageSection = document.querySelector(".collage__section");

export const toggleVisibility = (item, state) => {
  gsap.set(item, {
    display: () => {
      return state === "show" ? "block" : "none";
    },
  });
};

export const clearProps = (item, props) => {
  gsap.set(item, {
    clearProps: props,
  });
};

export const toggleCollageStyles = ({state, hover = false}) => {
  if (state === "add") {
    toggleVisibility(collage, "show");
    toggleVisibility(collageSection, "show");
  } else if (state === "remove") {
    clearProps(collage, "display");
    clearProps(collageSection, "display");
  }
};

export const toggleScrollBehaviour = ({state}) => {
  if (state === "add") {
    document.documentElement.classList.add("no-scroll");
    document.querySelector(".header").classList.add("fixed");
  } else if (state === "remove") {
    document.documentElement.classList.remove("no-scroll");
    document.querySelector(".header").classList.remove("fixed");
  }
};

export const replaceImages = (artistImages) => {
  const images = Object.values(artistImages);
  images.forEach((image, index) => {
    imagesArray[index].src = image ? `${image}` : "";
  });
};

export const replaceContent = ({
  name,
  country,
  richText,
  date,
  time = "TBA",
  scene,
  spotify,
}) => {
  artistName.textContent = name;
  artistCountry.textContent = country;
  artistRichText.innerHTML = richText;
  artistTime.textContent = time;
  artistScene.textContent = scene;
  artistSpotify.innerHTML = spotify;

  const sceneParent = artistScene.closest(".collage__meta-item");
  if (scene.trim() === "Velg scene") {
    sceneParent.style.display = "none";
  } else {
    sceneParent.style.display = "";
  }

  const dateObj = new Date(date.date);
  const dateDay = weekday[dateObj.getDay()];
  const dateDate = dateObj.getDate();
  const dateMonth = month[dateObj.getMonth()];

  if (!dateDay && !dateMonth) return;
  artistDate.textContent = `${dateDay} ${dateDate}. ${dateMonth} '24`;
};

export const getClickedArtistData = (artist) => {
  const artistHandle = artist
    .querySelector(".artist__name")
    .textContent.toLowerCase();
  return window.COLLAGE_DATA.find(
    (data) => data.artistName.toLowerCase() === artistHandle
  );
};

export const preloadImages = (urls) => {
  urls.forEach((url) => {
    const img = new Image();
    img.src = url;
  });
};

export const weekday = [
  "Søndag",
  "Mandag",
  "Tirsdag",
  "Onsdag",
  "Torsdag",
  "Fredag",
  "Lørdag",
];
export const month = [
  "jan",
  "feb",
  "mars",
  "apr",
  "mai",
  "juni",
  "juli",
  "aug",
  "sept",
  "okt",
  "nov",
  "des",
];
